







































































































































































import {
  Component, PropSync, Vue,
} from 'vue-property-decorator';

import ChangeBankAccountDialogViewModel
  from '@/vue-app/view-models/allianz-dashboard/allianz-actions/change-bank-account/change-bank-account-dialog-view-model';

@Component({
  name: 'ChangeBankAccountDialog',
  components: {
    SuccessChangeBankAccountDialog: () => import('@/vue-app/components/allianz-dashboard/allianz-actions/change-bank-account/SuccessChangeBankAccount.vue'),
  },
})
export default class ChangeBankAccountDialog extends Vue {
  @PropSync('isOpen', {
    type: Boolean,
    required: true,
    default: false,
  })
  synced_is_open!: boolean;

  change_bank_account_view_model = Vue.observable(new ChangeBankAccountDialogViewModel(this));

  back() {
    this.synced_is_open = false;
  }

  created() {
    this.change_bank_account_view_model.initialize();
  }
}
