import TYPES from '@/types';

import { clabe } from 'clabe-validator';
import { v4 as uuid } from 'uuid';
import Vue from 'vue';

// Infrastructure
import { clabeFormat, requiredRule } from '@/vue-app/utils/form-rules';
import { convertToLargerPossibleUnit } from '@/vue-app/utils/files';
import Functions from '@/modules/shared/infrastructure/utils/functions';

// Application
import {
  GetAllianzAccountQuery,
} from '@/modules/my-investment/allianz/allianz-account/application/queries';
import SearchAllianzFinancialInstitutionsQuery
  from '@/modules/my-investment/catalogs/allianz/financial-institution/application/queries/search-financial-institutions-query';
import {
  UpdateAccountNumberCommand,
} from '@/modules/my-investment/allianz/allianz-account/application/commands/';

// Domain
import {
  FinancialInstitutionEntity as AllianzFinancialInstitutionEntity,
} from '@/modules/my-investment/catalogs/allianz/financial-institution/domain/entities/financial-institution-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import { Values } from '@/modules/shared/domain/i18n/types';
import Translator from '@/modules/shared/domain/i18n/translator';

export default class ChangeBankAccountDialogViewModel {
  @Inject(TYPES.SEARCH_CATALOG_ALLIANZ_FINANCIAL_INSTITUTION_QUERY)
  private readonly searchAllianzFinancialInstitutionsQuery!:
    SearchAllianzFinancialInstitutionsQuery;

  @Inject(TYPES.GET_ALLIANZ_ACCOUNT_QUERY)
  private readonly get_allianz_account_query!: GetAllianzAccountQuery;

  @Inject(TYPES.UTIL_FUNCTIONS)
  readonly functions!: Functions;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.UPDATE_ACCOUNT_NUMBER_COMMAND)
  private readonly update_account_number_command!: UpdateAccountNumberCommand;

  readonly i18n_namespace = 'components.allianz-dashboard.change-bank-account';

  readonly limit_file_size = 4 * 1024 * 1024;

  readonly allowed_extensions = ['image/jpeg', 'image/jpg', 'image/png'];

  private readonly view: Vue;

  small_screen: null | boolean = null;

  invalid_file = false;

  bank = {
    clabe: '',
    code: '',
  };

  rules = {
    clabe: [requiredRule, clabeFormat],
    bank_institution: [requiredRule],
  };

  account_status: File | null = null;

  account_status_accept = 'image/png, image/jpeg, image/jpg';

  form_validity = false;

  is_loading = true;

  payload = {
    allianz_account_id: '',
    request_id: uuid(),
    clabe: '',
    bank: {
      id: '',
      description: '',
    },
    file: {
      mime_type: '',
      file_data: '',
    },
  };

  bank_institution = '';

  financial_institutions: Array<AllianzFinancialInstitutionEntity> = [];

  change_applied = false;

  constructor(view: Vue) {
    this.view = view;
  }

  get styled_file_name(): string {
    if (this.account_status) {
      return `${this.account_status.name.slice(0, 30)}${this.account_status.name.length > 35 ? '...' : ''}`;
    }

    return this.translate('account_status.placeholder')
      .toString();
  }

  get can_continue(): boolean {
    return this.form_validity && !!this.account_status && !!this.bank_institution
      && !this.invalid_file;
  }

  get styled_file_size(): string {
    if (this.account_status) {
      return convertToLargerPossibleUnit(this.account_status.size);
    }
    return '0 kb';
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  changeClabe = () => {
    if (this.bank.clabe.length >= 3) {
      this.bank_institution = '';
      this.filterInstitutions();
    }
  }

  formatBankInstitution = (bank_institution: string) => {
    let formatted_bank_institution = bank_institution;
    const internal_key_position = bank_institution.indexOf('SW');
    const dom_position = bank_institution.indexOf('(DOM');
    if (internal_key_position >= 0) {
      formatted_bank_institution = bank_institution.substring(0, internal_key_position);
    }
    if (dom_position >= 0) {
      formatted_bank_institution = bank_institution.substring(0, dom_position);
    }
    return formatted_bank_institution.trimEnd();
  }

  convertFileToBase64 = async (selected_file: File) => (
    this.functions.convert_file_to_base_64(selected_file)
  );

  setFileData = async (file: File) => {
    this.payload.file.mime_type = file.type;
    const base64 = await this.convertFileToBase64(file);
    if (base64) {
      this.payload.file.file_data = base64.toString();
    }
  };

  handleAccountStatus = async (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (target.files) {
      const [file] = target.files;
      if (file.size <= this.limit_file_size && this.allowed_extensions.indexOf(file.type) > -1) {
        this.account_status = file;
        this.setFileData(file);
        this.invalid_file = false;
      } else {
        this.invalid_file = true;
      }
    }
  }

  filterInstitutions() {
    this.is_loading = true;
    if (this.bank.clabe) {
      const clabe_without_space = this.bank.clabe.replace(/\s/g, '');
      const validation = clabe.validate(clabe_without_space);
      const bank_code = validation.code.bank;
      const is_valid = validation.formatOk;
      if (is_valid) {
        const search_bank = this.financial_institutions.find(
          (bank) => bank.cveBancaria === bank_code,
        );
        if (!search_bank) {
          this.message_notifier.showErrorNotification(
            this.translate('errors.load_financial_institution'),
          );
        }

        if (search_bank) {
          this.bank_institution = this.formatBankInstitution(search_bank.descEntFinan);
        }
      }
    }
    this.is_loading = false;
  }

  loadAllianzAccount = async () => {
    try {
      const customer_id = sessionStorage.getItem('user_id');
      const allianz_account = await this.get_allianz_account_query
        .execute({ customer_id });
      this.payload.allianz_account_id = allianz_account.id;
    } catch (error) {
      this.message_notifier.showErrorNotification(this.translate('errors.load_account'));
    }
  }

  loadFinancialInstitutions = async () => {
    try {
      this.financial_institutions = await this.searchAllianzFinancialInstitutionsQuery.execute();
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_financial_institution'));
    } finally {
      this.is_loading = false;
    }
  }

  continue = async () => {
    try {
      this.is_loading = true;
      const update_account_number_dto = {
        allianz_account_id: this.payload.allianz_account_id,
        request_id: this.payload.request_id,
        account_number: this.bank.clabe.replaceAll(' ', ''),
        bank_name: this.bank_institution,
        mime_type: this.payload.file.mime_type,
        file_data: this.payload.file.file_data,
      };
      await this.update_account_number_command.internalExecute(update_account_number_dto);
      this.change_applied = true;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.apply_change'));
    } finally {
      this.is_loading = false;
    }
  }

  accept = () => {
    this.view.$emit('accept');
  }

  initialize = async () => {
    await this.loadAllianzAccount();
    await this.loadFinancialInstitutions();
    this.is_loading = false;
  }
}
